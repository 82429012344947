export {}

const headerSearches = document.querySelectorAll(".m-header-search")
const mainOverlay = document.querySelector(".c-overlay-main")
const openers = document.querySelectorAll(".--js--main-search-opener")
const closers = document.querySelectorAll(".--js-main-search-closer")

headerSearches.forEach((headerSearch) => {
  const open = () => {
    headerSearch.classList.add("--open")
    mainOverlay?.classList.add("--show")
  }
  const close = () => {
    headerSearch.classList.remove("--open")
    mainOverlay?.classList.remove("--show")
  }

  openers.forEach((opener) => opener.addEventListener("click", open))
  closers.forEach((closer) => closer.addEventListener("click", close))
})
